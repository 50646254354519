<template>
  <ul class="inline-flex gap-x-4 md:gap-x-16 items-center">
    <li v-for="(step, stepIdx) in computedSteps" :key="step.stepText">
      <button
        :disabled="stepIdx > currentStepIdx"
        @click="onStepClick(stepIdx)"
      >
        <span
          :class="[
            stepClassNamesResolver[step.state].container,
            { 'cursor-default': disabled },
          ]"
        >
          <span :class="stepClassNamesResolver[step.state].indicator">
            <template v-if="stepIdx < currentStepIdx">
              <IconCheckmark class="w-2 h-2 md:w-4 md:h-4" />
            </template>
            <template v-else>
              {{ stepIdx + 1 }}
            </template>
          </span>
          {{ step.stepText }}
        </span>
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue";
import { IconCheckmark } from "@/components/atoms/Icon";

enum StepState {
  COMPLETED = "completed",
  CURRENT = "current",
  UPCOMING = "upcoming",
}
const props = defineProps<{
  steps: string[];
  disabled?: boolean;
}>();

const currentStepIdx = defineModel<{
  currentStepIdx: number;
}>("currentStepIdx");

const emit = defineEmits(["update:currentStepIdx"]);

const computedSteps = computed(() => {
  return props.steps?.map((step, idx) => {
    const state =
      idx < currentStepIdx.value
        ? StepState.COMPLETED
        : idx === currentStepIdx.value
          ? StepState.CURRENT
          : StepState.UPCOMING;
    return {
      stepText: step,
      state,
    };
  });
});

const commonContainerClassNames =
  "text-m-sm md:text-lg inline-flex gap-x-2 md:gap-x-4 items-center";
const commonIndicatorClassNames =
  "min-w-4 min-h-4 md:min-w-8 md:min-h-8 rounded-full border inline-flex items-center justify-center font-medium transition-colors";
const stepClassNamesResolver = {
  [StepState.COMPLETED]: {
    container: commonContainerClassNames,
    indicator: `${commonIndicatorClassNames} border-black text-black`,
  },
  [StepState.CURRENT]: {
    container: `${commonContainerClassNames} md:font-medium`,
    indicator: `${commonIndicatorClassNames} border-black bg-black text-white`,
  },
  [StepState.UPCOMING]: {
    container: `${commonContainerClassNames} text-grey`,
    indicator: `${commonIndicatorClassNames} border-grey`,
  },
};

const onStepClick = (stepIdx) => {
  if (!props.disabled) {
    emit("update:currentStepIdx", stepIdx);
  }
};
</script>
